<template>
  <!-- 交易时间 -->
  <div class="tradingHourPageBox">
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="form-search">
      <el-form-item label="开市时间">
        <el-time-picker v-model="formInline.openTime" size="small" value-format="HH:mm" placeholder="开市时间" />
      </el-form-item>
      <el-form-item label="闭市时间">
        <el-time-picker v-model="formInline.closeTime" size="small" value-format="HH:mm" placeholder="闭市时间" />
      </el-form-item>
      <el-form-item label="交易模式">
        <el-select v-model="formInline.tradeMode" size="small" placeholder="请选择">
          <el-option label="全部" value="" />
          <el-option label="撮合交易" value="10" />
          <el-option label="集采交易" value="20" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" class="high-button" @click="getdata">
          搜索
        </el-button>
        <el-button size="small" class="high-button" @click="getdata('reset')">
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="editAndAddRow">
          添加
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :operation-width="90"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
      <!-- 弹出的新增跟修改框 -->
    </div>
    <Dialog ref="dialog" dialog-width="35%" :edit-form-data="editFormData" :form-item="addAndEditFormItem" @getFormData="getFormData" />
  </div>
</template>

<script>
import Table from '../../components/Table'
import Pagination from '../../components/Pagination2'
import Dialog from '../../components/Dialog'
import { tradeTimeList, tradeTimeAdd } from '../../api/basicSetting'
export default {
  components: { Table, Pagination, Dialog },
  data() {
    return {
      editFormData: {},
      itemData: [
        { label: '开市时间', prop: 'openTime' },
        { label: '闭市时间', prop: 'closeTime' },
        { label: '交易日', prop: 'tradeDay', child: [{ name: '仅工作日', id: '00' }, { name: '每天', id: '01' }] },
        { label: '状态', prop: 'state', child: [{ name: '启用', id: '1' }, { name: '禁用', id: '0' }] },
        { label: '交易模式', prop: 'tradeMode', child: [{ name: '全部', id: '-1' }, { name: '撮合交易', id: '10' }, { name: '集采交易', id: '20' }, { name: '商城交易', id: '30' }] }
      ],
      addAndEditFormItem: [
        { label: '开始时间', type: 'timeSelect', value: 'openTime', valueFormat: 'HH:mm' },
        { label: '闭市时间', type: 'timeSelect', value: 'closeTime', valueFormat: 'HH:mm' },
        { label: '交易日', type: 'select', optionLabel: 'name', optionId: 'id', value: 'tradeDay', child: [{ name: '仅工作日', id: '00' }, { name: '每天', id: '01' }] },
        { label: '交易模式', type: 'select', optionLabel: 'name', optionId: 'id', value: 'tradeMode', child: [{ name: '全部', id: '-1' }, { name: '撮合交易', id: '10' }, { name: '集采交易', id: '20' }, { name: '商城交易', id: '30' }] },
        { label: '状态', type: 'select', optionLabel: 'name', optionId: 'id', value: 'state', child: [{ name: '启用', id: 1 }, { name: '禁用', id: 0 }] }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  activated() {
    this.getdata()
  },
  methods: {
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      tradeTimeList(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 新增或修改数据
    editAndAddRow(row) {
      this.editFormData = { ...row } || {}
      this.$refs.dialog.editFormVisible = true
    },
    // 获取到输入框的内容
    getFormData(value) {
      // 判断开始时间要小时结束时间
      const time1 = value.closeTime.split(':')
      const time2 = value.openTime.split(':')
      if (parseInt(time1[0]) < parseInt(time2[0])) {
        this.$message.error('开市时间不能遭遇闭市时间')
        return
      }
      if (parseInt(time1[0]) === parseInt(time2[0]) && parseInt(time1[1]) < parseInt(time2[1])) {
        this.$message.error('开市时间不能遭遇闭市时间')
        return
      }
      tradeTimeAdd(value, () => {
        this.getdata(value.id)
        this.$message.success('成功！')
        this.$refs.dialog.editFormVisible = false
      })
    }

  }
}
</script>
<style lang="scss">
.tradingHourPageBox {
  .form-search {
    margin-top: 20px;
  }
}
</style>
